.lia-style-content {
  position: relative;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-normal);
  color: var(--lia-bs-gray-900);
  cursor: pointer;
  padding-left: 18px;
  flex-shrink: 0;

  &:hover {
    color: var(--lia-bs-gray-900);
  }

  &.lia-is-active:before {
    position: absolute;
    content: '';
    top: 8px;
    left: 4px;
    height: 6px;
    width: 6px;
    border-radius: var(--lia-border-radius-50);
    background-color: var(--lia-bs-gray-900);
  }
}

.lia-style-node {
  color: var(--lia-bs-body-color);
  font-size: var(--lia-bs-font-size-sm);
  letter-spacing: var(--lia-letter-spacing-sm);
  font-weight: var(--lia-font-weight-md);
}

.lia-restricted-article {
  color: var(--lia-bs-gray-700);
}
